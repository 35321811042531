import { useState } from "react";
import { useLocation } from "react-router";
import moment from "moment";

import Layout from "../../Layout/Layout.js";
import TopNavBar from "../../TopNavBar/TopNavBar.js";
import LeftNavBar from "../../LeftNavBar/LeftNavBar.js";
import Main from "../../Main/Main.js";

import ModalMessage from "../../ModalMessage/ModalMessage";
import PropertyDetailsBox from "../../PropertyItem/PropertyDetailsBox/PropertyDetailsBox";
import Alink from "../../Alink/Alink";
import Button from "../../Button/Button";
import HelpInfo from "../../HelpInfo/HelpInfo";

import styles from "./MarketplaceLazyMinted.module.css";

import { ReactComponent as CheckGreenIcon } from "../../../assets/icons/check-green-small-icon.svg";

import property from "../../../assets/img/property1.png";

const MarketplaceLazyMinted = ({
  web3Handler,
  account,
  user,
  onClickVerify,
  signOut,
}) => {
  const { state } = useLocation();
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const threshold = state?.threshold;
  const minted = state?.minted;
  const totalSupply = state?.totalSupply;
  const pledgingDueTime = state?.pledgingDueTime;

  const progress = Math.round((minted / totalSupply) * 100);

  const isThresholdSurpassed = threshold >= progress;

  // const timeThresholdFormatted = moment(pledgingDueTime)
  //   .utcOffset("-0800")
  //   .format("LT PT | DD MMM YYYY");
  const timeThresholdFormatted =
    moment(pledgingDueTime).format("LT | DD MMM YYYY");

  const twitterMessage = encodeURI(
    `I've just invested in an NFT property. Check it out!`
  );

  return (
    <Layout
      topNavBar={
        <TopNavBar
          account={account}
          user={user}
          web3Handler={web3Handler}
          onClickVerify={onClickVerify}
          signOut={signOut}
        />
      }
      leftNavBar={<LeftNavBar selected="marketplace" />}
      main={
        <Main>
          <div className={styles.MarketplaceLazyMinted}>
            <div className={styles.ConfirmationBox}>
              <div className={styles.ConfirmationBoxBody}>
                <span className={styles.Icon}>
                  <CheckGreenIcon />
                </span>
                <span className={styles.Title}>{state?.amount} USDC</span>
                <span className={styles.Subtitle}>
                  You have successfully <Alink>lazyminted</Alink>!
                </span>
                <span className={styles.Description}>
                  <span>
                    Once the investment period has successfully ended, you will
                    be able to officially mint this NFT, have partial ownership
                    of this property and start earning rental income. The
                    investment period will successfully end if the minimum
                    threshold of NFTs has been lazy minted and the investment
                    period end time is reached.
                  </span>
                  <div className={styles.ConfirmationBoxPropertyDetails}>
                    <PropertyDetailsBox
                      title="Minimum Threshold"
                      details={<>{state?.threshold}%</>}
                      helper={
                        isThresholdSurpassed ? (
                          <>Threshold surpassed</>
                        ) : (
                          <>
                            Additional investment is needed
                            <br />
                            to meet the threshold
                          </>
                        )
                      }
                    />
                    <PropertyDetailsBox
                      title="Investment Period End Time"
                      details={timeThresholdFormatted}
                    />
                  </div>
                </span>
              </div>
              <div className={styles.ConfirmationBoxFooter}>
                <HelpInfo onClick={handleOpenModal}>
                  What if the threshold is not met?
                </HelpInfo>
              </div>
            </div>
            <div className={styles.PropertyDetails}>
              <div className={styles.PropertyDetailsImage}>
                <img src={property} />
              </div>
              <div className={styles.PropertyDetailsName}>{state?.name}</div>
              <div className={styles.TableRowWrapper}>
                <div className={styles.TableRow}>
                  <span>Total Property Value:</span>
                  <span>{state?.value} USDC</span>
                </div>
                <div className={styles.TableRow}>
                  <span>Total NFTs:</span>
                  <span>{state?.totalSupply}</span>
                </div>
                <div className={styles.TableRow}>
                  <span>Cost to own 1 NFT:</span>
                  <span>{state?.nftPrice} USDC</span>
                </div>
                <div className={styles.TableRow}>
                  <span>NFTs You've Lazy Minted:</span>
                  <span>{state?.shares}</span>
                </div>
              </div>
              <div className={styles.PropertyDetailsLinks}>
                <a
                  href={state?.etherscanUrl}
                  target="blank"
                  className={styles.PropertyDetailsLink}
                >
                  View On Blockchain
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?text=${twitterMessage}&via=closin`}
                  target="blank"
                  className={styles.PropertyDetailsLink}
                >
                  Share on Twitter{" "}
                </a>
              </div>
              <div className={styles.PropertyDetailsButton}>
                <Button large link={`/marketplace/purchase/${state?.id}`}>
                  View Property
                </Button>
              </div>
            </div>
          </div>
          {modalOpen && (
            <ModalMessage
              isOpen={modalOpen}
              onClickClose={handleCloseModal}
              message={
                <>
                  The seller of this property has specified a minimum number of
                  NFTs that need to be purchased prior to the end of the
                  investment period in order for this property to be
                  successfully sold on Closin. If this threshold is not met, you
                  will be refunded your entire investment amount.
                </>
              }
            />
          )}
        </Main>
      }
    />
  );
};

export default MarketplaceLazyMinted;
