import styles from "./Input.module.css";

const Input = ({ value = "", type = "text", onChange = () => {}, error }) => {
  return (
    <div className={styles.InputWrapper}>
      <div className={styles.Input}>
        <input type={type} value={value} onChange={onChange} />
      </div>
      <span className={styles.ErrorText}>{error}</span>
    </div>
  );
};

export default Input;
