import classnames from "classnames";

import styles from "./PropertyDetailsBox.module.css";

import InfoPopover from "../../InfoPopover/InfoPopover";

const PropertyDetailsBox = ({ title, tooltip, details, helper }) => {
  return (
    <div className={styles.PropertyDetailsBox}>
      <span className={styles.DetailsTitle}>
        {title}
        {tooltip && (
          <div className={styles.ToolTip}>
            <InfoPopover content={tooltip} />
          </div>
        )}
      </span>
      <span
        className={classnames(
          styles.DetailsContent,
          helper && styles.DetailsContentHelper
        )}
      >
        <span>{details}</span>
        {helper && <span>{helper}</span>}
      </span>
    </div>
  );
};

export default PropertyDetailsBox;
