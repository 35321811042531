/**
 * Returns an injected provider
 * @param walletType 'isMetaMask' | 'isCoinbaseWallet'
 * @returns Provider
 */
export function getProvider(walletType) {
  if (!window.ethereum) {
    throw new Error("No injected ethereum object.");
  }

  console.log(444, window.ethereum);
  return Array.isArray(window.ethereum.providers)
    ? window.ethereum.providers.find((provider) => provider[walletType])
    : window.ethereum;
}

export const isMetaMask = () => Boolean(window.ethereum?.isMetaMask);
export const isCoinBase = () =>
  Boolean(
    window.ethereum?.isCoinbaseWallet || window.ethereum?.overrideIsMetaMask
  );

export function getMetaMaskProvider() {
  if (!window.ethereum) {
    throw new Error("No injected ethereum object.");
  }

  return Array.isArray(window.ethereum.providers)
    ? window.ethereum.providers.find((provider) => provider["isMetaMask"])
    : window.ethereum;
}

export function getCoinbaseProvider() {
  if (!window.ethereum) {
    throw new Error("No injected ethereum object.");
  }

  return Array.isArray(window.ethereum.providers)
    ? window.ethereum.providers.find((provider) => provider["isCoinbaseWallet"])
    : null;
}

// export function isProviderInstalled(walletType) {
//   if (typeof window.ethereum === "undefined") {
//     throw new Error("No injected ethereum object.");
//   }

//   if (isMetaMask())
//   return window.ethereum
// } else {
//     return
// }
//     return Array.isArray(window.ethereum.providers)
//       ? window.ethereum.providers.find((provider) => provider[walletType])
//       : window.ethereum;
// }
