import { useState } from "react";

import WalletDropdown from "../WalletDropdown/WalletDropdown";

import styles from "./ButtonConnect.module.css";

import { ReactComponent as CreditCardImage } from "../../assets/icons/credit-card.svg";

const ButtonConnect = ({
  web3Handler,
  account,
  user,
  onClickVerify,
  signOut,
}) => {
  const [walletOpen, setWalletOpen] = useState(false);

  const handleOpenWallet = () => setWalletOpen(true);
  const handleCloseWallet = () => setWalletOpen(false);

  return (
    <div
      className={styles.ButtonConnect}
      onMouseEnter={handleOpenWallet}
      onMouseLeave={handleCloseWallet}
    >
      <span className={styles.ButtonConnectWrapper}>
        <CreditCardImage />
        {!account ? (
          <span>connect wallet</span>
        ) : (
          <span>{account.slice(0, 5) + "..." + account.slice(38, 42)}</span>
        )}
      </span>
      {walletOpen && (
        <div className={styles.WalletDropdown}>
          <WalletDropdown
            account={account}
            user={user}
            web3Handler={web3Handler}
            onClickVerify={onClickVerify}
            signOut={signOut}
          />
        </div>
      )}
    </div>
  );
};

export default ButtonConnect;
