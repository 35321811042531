import { useEffect, useState } from "react";
import classNames from "classnames";

import {
  getMetaMaskProvider,
  getCoinbaseProvider,
  isMetaMask,
  isCoinBase,
} from "../../lib/Network";

import { functions, httpsCallable } from "../../firebase.js";

import ModalMessage from "../ModalMessage/ModalMessage";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Alink from "../Alink/Alink";
import InfoPopoverLarge from "../InfoPopoverLarge/InfoPopoverLarge";
import VerificationBadge from "../VerificationBadge/VerificationBadge";

import styles from "./WalletDropdown.module.css";

import { ReactComponent as MetamaskIcon } from "../../assets/icons/metamask-icon.svg";
import { ReactComponent as CoinbaseIcon } from "../../assets/icons/coinbase-icon.svg";
import { ReactComponent as PhantomIcon } from "../../assets/icons/phantom-icon.svg";
import { ReactComponent as GlowIcon } from "../../assets/icons/glow-icon.svg";
import { ReactComponent as KaikasIcon } from "../../assets/icons/kaikas-icon.svg";

import { ReactComponent as MetamaskLogo } from "../../assets/img/metamask-logo.svg";

const WalletButton = ({ icon, text, onClick = () => {}, disabled = false }) => {
  return (
    <div
      className={classNames(
        styles.WalletButton,
        disabled && styles.WalletButtonDisabled
      )}
    >
      <span>{icon}</span>
      <span className={styles.WalletButtonText}>{text}</span>
      <a className={styles.Connect} onClick={disabled ? null : onClick}>
        connect
      </a>
    </div>
  );
};

const WalletSelection = ({ web3Handler }) => {
  const [modalOpen3, setModalOpen3] = useState(false);

  const handleOpenModal3 = () => setModalOpen3(true);
  const handleCloseModal3 = () => setModalOpen3(false);

  return (
    <>
      <>
        <span className={styles.BodyText}>
          Connect with one of the wallets to use Closin.{" "}
          <div className={styles.InfoPopover}>
            <InfoPopoverLarge
              width={266}
              content={
                "A crypto wallet is an application or hardware device that allows individuals to store and retrieve digital items."
              }
            >
              <Alink>What's a wallet?</Alink>
            </InfoPopoverLarge>
          </div>
          {!isMetaMask() && !isCoinBase() && (
            <div className={styles.DownloadWalletBodyText}>
              <span className={styles.DownloadWalletBodyTextTitle}>
                No compatible wallet detected!
              </span>
              <span className={styles.DownloadWalletBodyTextBody}>
                You will need to have either MetaMask or Coinbase Wallet
                downloaded to your browser to connect them to Closin.
              </span>
              <span className={styles.DownloadWalletBodyTextBody}>
                Refresh this page after downloading your wallet extension.
              </span>
              <Alink link="https://metamask.io/" newWindow>
                Download MetaMask
              </Alink>
              <br />
              <Alink link="https://www.coinbase.com/wallet/downloads" newWindow>
                Download Coinbase Wallet
              </Alink>
            </div>
          )}
        </span>
        <WalletButton
          icon={<MetamaskIcon />}
          text="Metamask"
          disabled={!isMetaMask()}
          onClick={web3Handler}
        />
        <WalletButton
          icon={<CoinbaseIcon />}
          text="Coinbase Wallet"
          disabled={!isCoinBase()}
          onClick={web3Handler}
        />
        {/* <WalletButton
          icon={<PhantomIcon />}
          text="Phantom"
          disabled
          onClick={() => {}}
          // onClick={handleOpenModal3}
        />
        <WalletButton
          icon={<GlowIcon />}
          text="Glow"
          disabled
          onClick={() => {}}
        />
        <WalletButton
          icon={<KaikasIcon />}
          text="Kaikas"
          disabled
          onClick={() => {}}
        /> */}
      </>
      {modalOpen3 && (
        <ModalMessage
          onClickClose={handleCloseModal3}
          icon={<MetamaskLogo />}
          title={<>Please connect your Metamask</>}
          button={<Button large>Connect Now</Button>}
        />
      )}
    </>
  );
};

const WalletConnected = ({
  account,
  isVerified,
  onClickVerify,
  signOut,
  user,
}) => {
  const isReview = user?.kycAction === "review";
  const isReject = user?.kycAction === "reject";

  return (
    <>
      <div className={styles.WalletConnectedHeader}>
        <span className={styles.WalletConnectedTitle}>Welcome!</span>
        <VerificationBadge isVerified={isVerified} />
      </div>
      {!isVerified && !isReview && !isReject && (
        <div className={styles.VerifyProfile}>
          <Alink onClick={onClickVerify}>Verify Your Identity To Invest</Alink>
        </div>
      )}
      <div className={styles.WalletConnectedBody}>
        <strong>Wallet Address:</strong>
        <br />
        {account}
      </div>
      <div className={styles.WalletConnectedFooter}>
        <Button large wide={false} onClick={signOut}>
          Log Out
        </Button>
      </div>
    </>
  );
};

const WalletConnectedEarlyBird = ({
  account,
  isVerified,
  onClickVerify,
  onClickClose,
  user,
}) => {
  const isReview = user?.kycAction === "review";
  const isReject = user?.kycAction === "reject";
  return (
    <>
      <div className={styles.WalletConnectedHeader}>
        <span className={styles.WalletConnectedTitle}>Welcome!</span>
        <VerificationBadge isVerified={isVerified} />
      </div>
      {!isVerified && !isReview && !isReject && (
        <div className={styles.VerifyProfileEarlyBird}>
          <Alink onClick={onClickVerify}>Verify Your Identity To Invest</Alink>
        </div>
      )}
      {isVerified && <>You are already verified and whitelisted!</>}
      <div className={styles.WalletConnectedBody}>
        <strong>Wallet Address:</strong>
        <br />
        {account}
      </div>
      <div className={styles.WalletConnectedFooter}>
        {!isVerified && !isReview && !isReject ? (
          <Button large wide={false} onClick={onClickVerify}>
            Verify Identity
          </Button>
        ) : (
          <Button wide={false} onClick={onClickClose}>
            Close
          </Button>
        )}
      </div>
    </>
  );
};

const WalletInputEmail = ({ account, onClickVerify, passedEmail }) => {
  const [email, setEmail] = useState();
  const [error, setError] = useState();

  const validateEmail = (email) => {
    return Boolean(
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    );
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handleContinueButton = async () => {
    if (validateEmail(email)) {
      setError("");

      const result = await httpsCallable(functions, "setUserEmail")({ email });
      if (!result.success) {
        // show error?
      }

      onClickVerify();
    } else {
      setError("Please enter a valid email address");
    }
  };

  return (
    <>
      {/* <div className={styles.WalletConnectedHeader}>
        <span className={styles.WalletConnectedTitle}>Email</span>
      </div> */}
      <div className={styles.WalletConnectedBody}>
        <strong>Your Email Address:</strong>
        <br />
        <Input
          type="email"
          value={email}
          onChange={handleChangeEmail}
          error={error}
        />
      </div>
      <div className={styles.WalletConnectedFooter}>
        <Button large wide={false} onClick={handleContinueButton}>
          Continue
        </Button>
      </div>
    </>
  );
};

const WalletDropdown = ({
  account,
  web3Handler,
  user,
  onClickVerify,
  signOut,
  onConnected,
  isEarlyBird = false, // EARLYBIRD
  handleClose, // EARLYBIRD
}) => {
  const [isVerifying, setIsVerifying] = useState(false);

  const handleOpenEmailModal = () => {
    setIsVerifying(true);
  };

  // const showRandom = Math.round(Math.random());

  // return (
  //   <div className={styles.WalletDropdown}>
  //     <WalletSelection web3Handler={web3Handler} />
  //   </div>
  // );

  useEffect(() => {
    if (account && onConnected) onConnected();
  }, [account]);

  return (
    <div className={styles.WalletDropdown}>
      {account ? (
        <>
          {!isVerifying ? (
            <>
              {isEarlyBird ? ( // EARLYBIRD
                <WalletConnectedEarlyBird
                  account={account}
                  isVerified={user.verified}
                  onClickVerify={handleOpenEmailModal}
                  onClickClose={handleClose}
                  user={user}
                />
              ) : (
                <WalletConnected
                  account={account}
                  isVerified={user.verified}
                  // onClickVerify={onClickVerify}
                  onClickVerify={handleOpenEmailModal}
                  signOut={signOut}
                  user={user}
                />
              )}
            </>
          ) : (
            <WalletInputEmail
              account={account}
              // isVerified={user.verified}
              onClickVerify={onClickVerify}
              passedEmail={user?.email}
            />
          )}
        </>
      ) : (
        <WalletSelection web3Handler={web3Handler} />
      )}
    </div>
  );
};

export default WalletDropdown;
