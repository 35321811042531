// import { Link } from "react-router-dom";

import styles from "./Alink.module.css";
import classnames from "classnames";

const Alink = ({ children, link, onClick, newWindow = false }) => {
  const aAttr = {};

  if (link) aAttr.href = link;
  if (onClick) aAttr.onClick = onClick;
  if (newWindow) aAttr.target = "_blank";

  return (
    <a {...aAttr} className={classnames(styles.Alink)}>
      {children}
    </a>
  );
};

export default Alink;
