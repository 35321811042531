import { Link } from "react-router-dom";
import classnames from "classnames";

import styles from "./PageHeaderNavigation.module.css";

const PageHeaderNavigation = ({ tabSelected }) => {
  return (
    <>
      <Link
        to="/my-investments/in-progress"
        className={classnames(
          styles.NavItem,
          tabSelected === "in-progress" && styles.Selected
        )}
      >
        In Progress
      </Link>{" "}
      |{" "}
      <Link
        to="/my-investments/completed"
        className={classnames(
          styles.NavItem,
          styles.Last,
          tabSelected === "completed" && styles.Selected
          // styles.Last
        )}
      >
        Completed
      </Link>
    </>
  );
};

export default PageHeaderNavigation;
