import { ethers } from "ethers";
import { useParams, Navigate } from "react-router";

import Layout from "../../Layout/Layout.js";
import TopNavBar from "../../TopNavBar/TopNavBar.js";
import LeftNavBar from "../../LeftNavBar/LeftNavBar.js";
import Main from "../../Main/Main.js";

import Button from "../../Button/Button";
import Alink from "../../Alink/Alink";
import PageDescription from "../../PageDescription/PageDescription";

import InvestmentsNotConnected from "../InvestmentsNotConnected/InvestmentsNotConnected";

import { useMintProperty } from "../../../hooks/useMintProperty";

import styles from "./InvestmentsRefundMessage.module.css";

import { ReactComponent as EngineTurnIcon } from "../../../assets/icons/engine-turn-icon.svg";

const InvestmentsRefundMessage = ({
  web3Handler,
  account,
  user,
  onClickVerify,
  signOut,
  usdcDecimals,
  marketplace,
}) => {
  const { id } = useParams();
  const { property, loading, mintProperty, justMinted } = useMintProperty({
    id,
    usdcDecimals,
    marketplace,
    user,
  });

  const mintedNfts = property?.vouchersOwned?.toNumber();
  const nftPrice = property?.nftPriceToUsdc;

  if (justMinted)
    return <Navigate replace to={`/my-investments/refunded-message/${id}`} />;

  return (
    <Layout
      topNavBar={
        <TopNavBar
          account={account}
          user={user}
          web3Handler={web3Handler}
          onClickVerify={onClickVerify}
          signOut={signOut}
        />
      }
      leftNavBar={<LeftNavBar selected="my-investments" />}
      main={
        <Main>
          {!account ? (
            <InvestmentsNotConnected
              account={account}
              web3Handler={web3Handler}
            />
          ) : loading ? (
            <span className={styles.Loading}>Loading...</span>
          ) : (
            <PageDescription
              icon={<EngineTurnIcon />}
              title={
                <>
                  Proceed to get a refund for your NFTs for:
                  <br />
                  {ethers.utils.parseBytes32String(property.name)}
                </>
              }
              message={
                <>
                  You have lazy minted NFTs for this property, which has
                  reserved your right to mint and secure your NFTs for this
                  property. However, the minimum investment threshold was not
                  reached and the investment was canceled. You are now entitled
                  to a refund of your investment.
                  <br />
                  <br />
                  You will need to pay the blockchain fees to receive your
                  refund, which will will require you to have less than $0.01
                  worth of MATIC in your MetaMask wallet.
                  <br />
                  <br />
                  Need help getting MATIC into your MetaMask wallet?{" "}
                  <Alink
                    newWindow
                    link=" https://decentralizedcreator.com/send-matic-from-coinbase-to-metamask/"
                  >
                    View this easy guide
                  </Alink>
                </>
              }
              button={
                <>
                  {property.allVouchersRefunded ? (
                    <Button large muted>
                      No NFTs left to refund
                    </Button>
                  ) : (
                    <Button large onClick={mintProperty}>
                      Get refund for {mintedNfts} NFTs at {nftPrice} USDC each
                    </Button>
                  )}
                </>
              }
            />
          )}
        </Main>
      }
    />
  );
};

export default InvestmentsRefundMessage;
