import styles from "./ModalMessage.module.css";

import { ReactComponent as CloseModalIcon } from "../../assets/icons/close-modal-icon.svg";

const ModalMessage = ({
  title,
  icon,
  message,
  button,
  showCloseCross = true,
  onClickClose = () => {},
}) => {
  const hanldeCloseModal = onClickClose;

  return (
    <div className={styles.ModalMessage}>
      <div className={styles.ModalMessageBanner}>
        {showCloseCross && (
          <span className={styles.CloseButton} onClick={hanldeCloseModal}>
            <CloseModalIcon />
          </span>
        )}
        {icon && <span className={styles.Icon}>{icon}</span>}
        <span className={styles.Title}>{title}</span>
        {message && <span className={styles.Message}>{message}</span>}
        {button && <div className={styles.Button}>{button}</div>}
      </div>
    </div>
  );
};

export default ModalMessage;
