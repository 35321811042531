import "./Layout.css";
import classnames from "classnames";

const Layout = ({ topNavBar, leftNavBar, rightNavBar, main }) => {
  return (
    <div
      className={classnames("Layout", !rightNavBar ? "NoGridRightNavBar" : "")}
    >
      <div className="GridTopNavBar">{topNavBar}</div>
      <div className="GridLeftNavBar">{leftNavBar}</div>
      {rightNavBar && <div className="GridRightNavBar">{rightNavBar}</div>}
      <div className="GridMain">{main}</div>
    </div>
  );
};

export default Layout;
