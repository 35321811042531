// import { ethers } from "ethers";
import { useState, useEffect, useCallback } from "react";
import { toUsdc } from "../lib/Currency";

// import MarketplaceAbi from "../contractsData/Marketplace.json";
import EscrowAddress from "../contractsData/Escrow-address.json";

const useMarketplacePurchase = ({ id, usdcDecimals, usdc, marketplace }) => {
  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState({});

  const loadMarketplaceProperty = useCallback(async () => {
    const item = await marketplace.getPropertyStruct(id);
    const nftPriceToUsdc = toUsdc(item.tokenPrice.toString(), usdcDecimals);

    setLoading(false);
    setProperty({ ...item, nftPriceToUsdc });
  }, [id, usdcDecimals, marketplace]);

  useEffect(() => {
    loadMarketplaceProperty();
  }, [loadMarketplaceProperty]);

  const purchaseProperty = useCallback(
    async (id, tokenPrice, tokens) => {
      setLoading(true);

      // console.log(tokenPrice, tokens, String(tokenPrice * tokens));

      const amount = String(tokenPrice * tokens);
      const amountToUsdc = toUsdc(amount, usdcDecimals);

      const tx1 = await usdc.approve(EscrowAddress.address, amount);

      await tx1.wait();

      // try {
      const tx2 = await marketplace.purchasePropertyEquity(id, tokens);

      await tx2.wait();
      // } catch (error) {}

      setLoading(false);

      return { amount: amountToUsdc, tx: tx2.hash };
    },
    [marketplace, usdc, usdcDecimals]
  );

  return { property, loading, purchaseProperty };
};

export { useMarketplacePurchase };
