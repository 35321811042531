import styles from "./PropertyImage.module.css";

const PropertyImage = ({ property }) => {
  return (
    <div className={styles.PropertyImage}>
      <img src={property} />
    </div>
  );
};

export default PropertyImage;
