import styles from "./PageHeader.module.css";

const PageHeader = ({ title, children }) => {
  return (
    <div className={styles.PageHeader}>
      <span className={styles.Title}>{title}</span>
      <div className={styles.RightNav}>{children}</div>
    </div>
  );
};

export default PageHeader;
