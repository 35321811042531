import { ethers } from "ethers";
import { useParams, Navigate } from "react-router";

import Layout from "../../Layout/Layout";
import TopNavBar from "../../TopNavBar/TopNavBar";
import LeftNavBar from "../../LeftNavBar/LeftNavBar";
import Main from "../../Main/Main";

import Button from "../../Button/Button";
import Alink from "../../Alink/Alink";
import PageDescription from "../../PageDescription/PageDescription";

import InvestmentsNotConnected from "../InvestmentsNotConnected/InvestmentsNotConnected";

import { useMintProperty } from "../../../hooks/useMintProperty";

import styles from "./InvestmentsMintMessage.module.css";

import { ReactComponent as EngineTurnIcon } from "../../../assets/icons/engine-turn-icon.svg";

const InvestmentsMintMessage = ({
  web3Handler,
  account,
  user,
  onClickVerify,
  signOut,
  usdcDecimals,
  marketplace,
}) => {
  const { id } = useParams();
  const { property, loading, mintProperty, justMinted } = useMintProperty({
    id,
    usdcDecimals,
    marketplace,
    user,
  });

  if (justMinted)
    return <Navigate replace to={`/my-investments/minted-message/${id}`} />;

  return (
    <Layout
      topNavBar={
        <TopNavBar
          account={account}
          user={user}
          web3Handler={web3Handler}
          onClickVerify={onClickVerify}
          signOut={signOut}
        />
      }
      leftNavBar={<LeftNavBar selected="my-investments" />}
      main={
        <Main>
          {!account ? (
            <InvestmentsNotConnected
              account={account}
              web3Handler={web3Handler}
            />
          ) : loading ? (
            <span className={styles.Loading}>Loading...</span>
          ) : (
            <PageDescription
              icon={<EngineTurnIcon />}
              title={
                <>
                  Proceed to mint your NFTs for: <br />
                  {ethers.utils.parseBytes32String(property.name)}
                </>
              }
              message={
                <>
                  You have lazy minted NFTs for this property, which has
                  reserved your right to mint and secure your NFTs for this
                  property. In order to claim ownership of the NFTs and earn
                  rental income for this property, you will now need to
                  officially mint the NFTs that you lazy minted. <br />
                  <br />
                  You do not need to pay the price of the NFT again, as you
                  already did this when you lazy minted. You will only need to
                  pay the blockchain fees to mint, which will require you to
                  have less than $0.01 worth of MATIC in your MetaMask wallet.
                  <br />
                  <br />
                  Need help getting MATIC into your MetaMask wallet?{" "}
                  <Alink
                    newWindow
                    link=" https://decentralizedcreator.com/send-matic-from-coinbase-to-metamask/"
                  >
                    View this easy guide
                  </Alink>
                </>
              }
              button={
                <>
                  {property.allVouchersRedeemed ? (
                    <Button large muted>
                      No NFTs left to mint
                    </Button>
                  ) : (
                    <Button large onClick={mintProperty}>
                      Mint {property.vouchersOwned.toNumber()} NFTs
                    </Button>
                  )}
                </>
              }
            />
          )}
        </Main>
      }
    />
  );
};

export default InvestmentsMintMessage;
