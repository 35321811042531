import BerbixVerify from "berbix-react";

const BerbixVerificationClosin = ({ clientToken, onComplete }) => {
  return (
    <BerbixVerify
      clientToken={clientToken}
      showInModal
      onComplete={onComplete}
    />
  );
};

export default BerbixVerificationClosin;
