import Button from "../../Button/Button";
import PageDescription from "../../PageDescription/PageDescription";

// import styles from "./MarketplaceVerifiedIdentityMessage.module.css";

import { ReactComponent as ThankYouIcon } from "../../../assets/icons/thank-you-icon.svg";

const MarketplaceVerifiedIdentityMessage = () => {
  return (
    <PageDescription
      icon={<ThankYouIcon />}
      title={<>Thank you for verifying your identity</>}
      message={
        <>You may now invest in any property listed on the Closin marketplace</>
      }
      button={
        <Button large link="/marketplace">
          Continue
        </Button>
      }
    />
  );
};

export default MarketplaceVerifiedIdentityMessage;
