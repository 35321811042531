import { Link } from "react-router-dom";
import classnames from "classnames";

import styles from "./PageHeaderNavigation.module.css";

const PageHeaderNavigation = ({ tabSelected }) => {
  return (
    <>
      <Link
        to="/marketplace/available"
        className={classnames(
          styles.NavItem,
          tabSelected === "available" && styles.Selected
        )}
      >
        Available Properties
      </Link>{" "}
      |{" "}
      <Link
        to="/marketplace/completed"
        className={classnames(
          styles.NavItem,
          tabSelected === "completed" && styles.Selected,
          styles.Last
        )}
      >
        Completed Properties
      </Link>
    </>
  );
};

export default PageHeaderNavigation;
