import React, { useState } from "react";

import styles from "./HelpInfo.module.css";

import { ReactComponent as HelpCircleIcon } from "../../assets/icons/help-circle-icon.svg";

const HelpInfo = ({ children, onClick = () => {} }) => {
  return (
    <span className={styles.HelpInfo} onClick={onClick}>
      <span className={styles.HelpInfoText}>{children}</span>
      <HelpCircleIcon />
    </span>
  );
};

export default HelpInfo;
