import { Link } from "react-router-dom";

import styles from "./Button.module.css";
import classnames from "classnames";

const ButtonWrapper = ({ children, ...props }) => {
  if (props.to) {
    return <Link {...{ onClick: undefined, ...props }}>{children}</Link>;
  } else {
    return <span {...{ to: undefined, ...props }}>{children}</span>;
  }
};

const Button = ({
  children,
  onClick = () => {},
  muted = false,
  large = false,
  link = "",
  wide = true,
  ghost = false,
  style = {},
}) => {
  return (
    <ButtonWrapper
      to={link}
      className={classnames(
        styles.Button,
        muted && styles.Muted,
        large && styles.Large,
        wide && styles.Wide,
        ghost && styles.Ghost
      )}
      onClick={onClick}
      style={style}
    >
      <span>{children}</span>
    </ButtonWrapper>
  );
};

export default Button;
