import styles from "./PageDescription.module.css";

const PageDescription = ({ title, icon, message, button }) => {
  return (
    <div className={styles.PageDescription}>
      <div className={styles.PageDescriptionWrapper}>
        {icon && <span className={styles.Icon}>{icon}</span>}
        {title && <span className={styles.Title}>{title}</span>}
        {message && <span className={styles.Message}>{message}</span>}
        {button && <div className={styles.Button}>{button}</div>}
      </div>
    </div>
  );
};

export default PageDescription;
