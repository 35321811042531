import { ethers } from "ethers";
import { useState, useEffect, useCallback } from "react";

import MarketplaceAbi from "../contractsData/Marketplace.json";
import MarketplaceAddress from "../contractsData/Marketplace-address.json";

const toUsdc = (value, decimals, trailing = false) => {
  let result = ethers.utils.formatUnits(value, decimals);
  if (!trailing) result = result.split(".")[0];

  return result;
};

const useMarketplace = ({ usdcDecimals, marketplace }) => {
  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState([]);

  const loadMarketplaceProperties = useCallback(async () => {
    const items = []; // await marketplace.getProperties(); EARLYBIRD

    // let properties = [];
    // for (let i = 0; i < items.length; i++) {
    //   // TO-DO: Refactor to avoid multiple requests for fetching list of properties
    //   // const item = await marketplace.getPropertyStruct(i);
    //   const item = items[i];
    //   const tokenPriceFormatted = toUsdc(
    //     item.tokenPrice.toString(),
    //     usdcDecimals
    //   );

    //   properties.push({ ...item, tokenPriceFormatted });
    // }

    const properties = items.map((item) => {
      const tokenPriceFormatted = toUsdc(
        item.tokenPrice.toString(),
        usdcDecimals
      );
      return { ...item, tokenPriceFormatted };
    });

    setLoading(false);
    setProperties(properties);
  }, [usdcDecimals, marketplace]);

  useEffect(() => {
    loadMarketplaceProperties();
  }, [loadMarketplaceProperties]);

  return { properties, loading };
};

export { useMarketplace };
