import Button from "../../Button/Button";
import PropertyDetailsBox from "../../PropertyItem/PropertyDetailsBox/PropertyDetailsBox";
import PropertyProgressBar from "../../PropertyItem/PropertyProgressBar/PropertyProgressBar";
import PropertyTimeRemaining from "../../PropertyItem/PropertyTimeRemaining/PropertyTimeRemaining";
import PropertyImage from "../../PropertyItem/PropertyImage/PropertyImage";

import styles from "./MarketplacePropertyItem.module.css";

import property from "../../../assets/img/property1.png";

const LeftExtra = () => {
  return (
    <a
      href="https://closin.com/property"
      target="_blank"
      className={styles.LeftButton}
    >
      Financial Details
    </a>
  );
};

const MarketplacePropertyItem = ({
  completed = false,
  id,
  name,
  threshold = 0,
  value = 0,
  totalSupply = 0,
  minted = 0,
  nftPrice = 0,
  projectedIrr = 0,
  cashPayout = 0,
  pledgingDueTime = 0,
  owned = 0,
}) => {
  const progress = Math.round((minted / totalSupply) * 100);

  const totalSupplyFormatted = totalSupply.toLocaleString();
  const valueFormatted = value.toLocaleString();
  const mintedFormatted = minted.toLocaleString();
  const nftPriceFormatted = nftPrice.toLocaleString();
  const projectedIrrFormatted = projectedIrr;

  const isThresholdSurpassed = progress >= threshold;

  const PropertyButton = () => {
    if (completed) {
      return <Button muted>Ended</Button>;
    } else {
      return <Button link={`/marketplace/purchase/${id}`}>Invest</Button>;
    }
  };

  return (
    <div className={styles.PropertyItem}>
      <div className={styles.Left}>
        <PropertyImage property={property} />
        {!completed && <PropertyTimeRemaining time={pledgingDueTime} />}
        {completed && <LeftExtra />}
      </div>
      <div className={styles.Center}>
        <div className={styles.PropertyDetails}>
          <div className={styles.Top}>
            <span className={styles.PropertyTitle}>{name}</span>
          </div>
          <div className={styles.Progress}>
            <PropertyProgressBar
              progress={progress}
              mintedFormatted={mintedFormatted}
              totalSupplyFormatted={totalSupplyFormatted}
            />
          </div>
          <div className={styles.Details1}>
            <PropertyDetailsBox
              title="Minimum Threshold"
              details={<>{threshold}%</>}
              helper={
                isThresholdSurpassed ? (
                  <>Threshold surpassed</>
                ) : (
                  <>
                    Additional investment is needed
                    <br />
                    to meet the threshold
                  </>
                )
              }
            />
          </div>
          <div className={styles.Details2}>
            <PropertyDetailsBox
              title="Total Property Value"
              details={<>{valueFormatted} USDC</>}
            />
          </div>
          <div className={styles.Details3}>
            <PropertyDetailsBox
              title="Total NFTs"
              details={totalSupplyFormatted}
            />
          </div>
          <div className={styles.Details4}>
            <PropertyDetailsBox
              title="Cost To Own 1 NFT"
              details={<>{nftPriceFormatted} USDC</>}
            />
          </div>
          <div className={styles.Details5}>
            <PropertyDetailsBox
              title="NFTs You've Lazy Minted"
              tooltip={
                <>
                  This is the number of NFTs that you purchased when you made
                  <br />
                  your investment. Because the investment period has not ended
                  <br />
                  yet, we use a “lazy minting” process so you don’t officially
                  <br />
                  mint the NFT and pay for gas fees until we know the investment
                  <br />
                  period has completed. Once the investment period is complete,
                  <br />
                  you will have the ability to officially mint your NFTs and own
                  <br />a part of the property.
                </>
              }
              details={owned}
            />
          </div>
          <div className={styles.Details6}>
            <PropertyDetailsBox
              title="Projected IRR"
              tooltip={
                <>
                  The internal rate of return (IRR) is a yearly percentage equal
                  <br />
                  to the amount of expected appreciation plus positive cash flow
                  <br />
                  for the property for each year. As this is an estimation, the
                  <br />
                  actual number may vary.
                </>
              }
              details={<>{projectedIrrFormatted}%</>}
            />
          </div>
          <div className={styles.Details7}>
            <PropertyDetailsBox
              title="Cash Payout"
              tooltip={
                <>
                  The cash payout is the annualized monthly rental payments you
                  <br />
                  can expect to receive as an owner of the property as a<br />
                  percentage of your investment amount. For example, a 5% cash
                  <br />
                  payout with $1,000 invested means you can expect to receive
                  <br />
                  $50 per year from your investment.
                </>
              }
              details={<>{cashPayout}%</>}
            />
          </div>
        </div>
      </div>
      <div className={styles.Right}>
        <PropertyButton />
      </div>
    </div>
  );
};

export default MarketplacePropertyItem;
