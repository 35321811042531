import ModalMessage from "../ModalMessage/ModalMessage";
import Button from "../Button/Button";

import { ReactComponent as WalletIcon } from "../../assets/icons/wallet-icon.svg";

const ModalMessageConnectWallet = ({ onClickClose, onClickConnect }) => (
  <ModalMessage
    onClickClose={onClickClose}
    icon={<WalletIcon />}
    title={<>Please connect your wallet </>}
    message={<>You will need to connect your wallet before you can invest.</>}
    button={
      <Button large onClick={onClickConnect}>
        connect wallet
      </Button>
    }
  />
);
export default ModalMessageConnectWallet;
