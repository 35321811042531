import {
  getDateFromTimestamp,
  getSecondsTimestamp,
} from "../../../lib/DateTime";

import Button from "../../Button/Button";
import PropertyDetailsBox from "../../PropertyItem/PropertyDetailsBox/PropertyDetailsBox";
import Alink from "../../Alink/Alink";
import PropertyProgressBar from "../../PropertyItem/PropertyProgressBar/PropertyProgressBar";
import PropertyTimeRemaining from "../../PropertyItem/PropertyTimeRemaining/PropertyTimeRemaining";
import PropertyImage from "../../PropertyItem/PropertyImage/PropertyImage";

import styles from "./InvestmentsPropertyItem.module.css";

import property from "../../../assets/img/property1.png";

const LeftExtra = () => {
  return (
    <a
      href="https://www.zillow.com/homedetails/1550-Technology-Dr-UNIT-2089-San-Jose-CA-95110/69301757_zpid/"
      target="_blank"
      className={styles.LeftButton}
    >
      View property on Zillow
    </a>
  );
};

const InvestmentsPropertyItem = ({
  id,
  completed = false,
  name,
  threshold = 0,
  // value = 0,
  totalSupply = 0,
  minted = 0,
  // nftPrice = 0,
  projectedIrr = 0,
  cashPayout = 0,
  rentalEarned = 0,
  pledgingDueTime = 0,
  owned = 0,
  vouchersRedeemed = 0,
  vouchersRefunded = 0,
  vouchersOwned = 0,
  handleOpenModal = () => {},
}) => {
  const progress = Math.round((minted / totalSupply) * 100);

  const totalSupplyFormatted = totalSupply.toLocaleString();
  const mintedFormatted = minted.toLocaleString();
  const rentalEarnedFormatted = rentalEarned.toLocaleString();
  const projectedIrrFormatted = projectedIrr;

  const isAmountThresholdSurpassed = progress >= threshold;

  const isTimeThresholdSurpassed =
    pledgingDueTime <= getSecondsTimestamp(Date.now());

  const invested = isTimeThresholdSurpassed && isAmountThresholdSurpassed;

  const cancelled = isTimeThresholdSurpassed && !isAmountThresholdSurpassed;

  const allVouchersRedeemed = vouchersRedeemed >= vouchersOwned;
  const allVouchersRefunded = vouchersRefunded >= vouchersOwned;

  const PropertyButton = ({ id }) => {
    if (invested) {
      if (!allVouchersRedeemed)
        return (
          <Button link={`/my-investments/mint-message/${id}`}>Mint NFTs</Button>
        );
    } else if (cancelled) {
      if (!allVouchersRefunded)
        return (
          <Button link={`/my-investments/refund-message/${id}`}>
            Get Refund
          </Button>
        );
      //   return <Button muted>Cancelled</Button>;
    } else {
      return (
        <Button link={`/my-investments/purchase/${id}`}>Invest More</Button>
      );
    }
  };

  return (
    <div className={styles.PropertyItem}>
      <div className={styles.Left}>
        <PropertyImage property={property} />
        {!completed && <PropertyTimeRemaining time={pledgingDueTime} />}
        <LeftExtra />
      </div>
      <div className={styles.Center}>
        <div className={styles.PropertyDetails}>
          <div className={styles.Top}>
            <span className={styles.PropertyTitle}>{name}</span>
          </div>
          <div className={styles.Progress}>
            <PropertyProgressBar
              progress={progress}
              mintedFormatted={mintedFormatted}
              totalSupplyFormatted={totalSupplyFormatted}
            />
          </div>
          <div className={styles.Details1}>
            {!completed && (
              <PropertyDetailsBox
                title="Minimum Threshold"
                details={<>{threshold}%</>}
                helper={
                  isAmountThresholdSurpassed ? (
                    <>Threshold surpassed</>
                  ) : (
                    <>
                      Additional investment is needed
                      <br />
                      to meet the threshold
                    </>
                  )
                }
              />
            )}
          </div>
          <div className={styles.Details2}>
            <PropertyDetailsBox
              title="Total NFTs"
              details={totalSupplyFormatted}
            />
          </div>
          <div className={styles.Details3}>
            <PropertyDetailsBox
              title={
                allVouchersRedeemed
                  ? "NFTs You've Minted"
                  : "NFTs You've Lazy Minted"
              }
              tooltip={
                <>
                  This is the number of NFTs that you purchased when you made
                  <br />
                  your investment. Because the investment period has not ended
                  <br />
                  yet, we use a “lazy minting” process so you don’t officially
                  <br />
                  mint the NFT and pay for gas fees until we know the investment
                  <br />
                  period has completed. Once the investment period is complete,
                  <br />
                  you will have the ability to officially mint your NFTs and own
                  <br />a part of the property.
                </>
              }
              details={owned}
            />
          </div>
          <div className={styles.Details4}>
            <PropertyDetailsBox
              title="Projected IRR"
              tooltip={
                <>
                  The internal rate of return (IRR) is a yearly percentage equal
                  <br />
                  to the amount of expected appreciation plus positive cash flow
                  <br />
                  for the property for each year. As this is an estimation, the
                  <br />
                  actual number may vary.
                </>
              }
              details={<>{projectedIrrFormatted}%</>}
            />
          </div>
          <div className={styles.Details5}>
            <PropertyDetailsBox
              title="Cash Payout"
              tooltip={
                <>
                  The cash payout is the annualized monthly rental payments you
                  <br />
                  can expect to receive as an owner of the property as a<br />
                  percentage of your investment amount. For example, a 5% cash
                  <br />
                  payout with $1,000 invested means you can expect to receive
                  <br />
                  $50 per year from your investment.
                </>
              }
              details={<>{cashPayout}%</>}
            />
          </div>
          <div className={styles.Details7}>
            {/* {completed && invested && (
              <PropertyDetailsBox
                title="Rental Income Earned"
                details={<>{rentalEarnedFormatted} USDC</>}
              />
            )} */}
          </div>
          <div className={styles.Details6}>
            <PropertyButton id={id} />
          </div>
        </div>
      </div>
      {!allVouchersRedeemed && invested && (
        <div className={styles.Bottom}>
          <div className={styles.FooterText}>
            The investment period has ended and lazy minting has finished. You
            will need to officially mint your NFTs to claim ownership and start
            earning rental income.
          </div>
        </div>
      )}
      {!allVouchersRefunded && cancelled && (
        <div className={styles.Bottom}>
          <div className={styles.FooterText}>
            The minimum threshold was not met by the deadline and this property
            investment has been canceled. <br />
            Press the above button to refund your funds to your wallet.{" "}
            <Alink onClick={handleOpenModal}>Why I am seeing this?</Alink>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestmentsPropertyItem;
