import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { httpsCallable } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBjQ5NtqP0s7-AkT6l0JSud1VTkUBSUwqk",
  authDomain: "closin-poc.firebaseapp.com",
  projectId: "closin-poc",
  storageBucket: "closin-poc.appspot.com",
  messagingSenderId: "980403456112",
  appId: "1:980403456112:web:13903499c7f3edae95ccb5",
  measurementId: "G-4QX6MKVSKP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
const functions = getFunctions();

// auth.setPersistence(inMemoryPersistence);
if (process.env.NODE_ENV === "development") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export { app, auth, functions, httpsCallable, analytics };
