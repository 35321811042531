import styles from "./VerificationBadge.module.css";
import classNames from "classnames";

import { ReactComponent as VerificationCheck } from "../../assets/icons/verification-check.svg";
import { ReactComponent as VerificationCheckUnverified } from "../../assets/icons/verification-check-unverified.svg";

const VerificationBadge = ({ isVerified = false }) => {
  return (
    <span
      className={classNames(
        styles.VerificationBadge,
        !isVerified && styles.VerificationBadgeUnverified
      )}
    >
      {isVerified ? (
        <>
          <span>Verified</span>
          <VerificationCheck />
        </>
      ) : (
        <>
          <span>Unverified</span>
          <VerificationCheckUnverified />
        </>
      )}
    </span>
  );
};

export default VerificationBadge;
