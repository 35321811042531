import styles from "./PageMessage.module.css";

const PageMessage = ({ title, icon, message, button }) => {
  return (
    <div className={styles.PageMessage}>
      <div className={styles.PageMessageWrapper}>
        {icon && <span className={styles.Icon}>{icon}</span>}
        <span className={styles.Title}>{title}</span>
        {message && <span className={styles.Message}>{message}</span>}
        {button && <div className={styles.Button}>{button}</div>}
      </div>
    </div>
  );
};

export default PageMessage;
