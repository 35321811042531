import ButtonConnect from "../ButtonConnect/ButtonConnect";

import styles from "./TopNavBar.module.css";

import { ReactComponent as Logo } from "../../assets/img/closin.svg";

const TopNavBar = ({ web3Handler, account, user, onClickVerify, signOut }) => {
  return (
    <div className={styles.TopNavBar}>
      <div className={styles.Logo}>
        <Logo />
      </div>
      <div className={styles.Buttons}>
        <ButtonConnect
          account={account}
          user={user}
          web3Handler={web3Handler}
          onClickVerify={onClickVerify}
          signOut={signOut}
        />
      </div>
    </div>
  );
};

export default TopNavBar;
