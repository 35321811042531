import { useParams } from "react-router";

import Button from "../../Button/Button";

import Layout from "../../Layout/Layout";
import TopNavBar from "../../TopNavBar/TopNavBar";
import LeftNavBar from "../../LeftNavBar/LeftNavBar";
import Main from "../../Main/Main";

import PageDescription from "../../PageDescription/PageDescription";

// import styles from "./InvestmentsMintedMessage.module.css";

import { ReactComponent as CheckGreenIcon } from "../../../assets/icons/check-green-icon.svg";

const InvestmentsMintedMessage = ({
  web3Handler,
  account,
  user,
  onClickVerify,
  signOut,
}) => {
  const { id } = useParams();

  return (
    <Layout
      topNavBar={
        <TopNavBar
          account={account}
          user={user}
          web3Handler={web3Handler}
          onClickVerify={onClickVerify}
          signOut={signOut}
        />
      }
      leftNavBar={<LeftNavBar selected="my-investments" />}
      main={
        <Main>
          <PageDescription
            icon={<CheckGreenIcon />}
            title={<>You have successfully minted the NFTs</>}
            message={
              <>
                You are now a fractional owner of the property and will start
                earning rental income.
              </>
            }
            button={
              <Button large link="/my-investments/completed">
                View Your Investment
              </Button>
            }
          />
        </Main>
      }
    />
  );
};

export default InvestmentsMintedMessage;
