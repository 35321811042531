export function getSecondsTimestamp(date) {
  return Math.floor(date / 1000);
}

export function getMilisecondsTimestamp(date) {
  return Math.floor(date * 1000);
}

export function getDateFromTimestamp(timestamp) {
  return new Date(timestamp);
}

export function diffDates(date, laterDate) {
  return laterDate - date;
}
