import { ethers } from "ethers";
import { getSecondsTimestamp } from "../../../lib/DateTime";

import Layout from "../../Layout/Layout.js";
import TopNavBar from "../../TopNavBar/TopNavBar.js";
import LeftNavBar from "../../LeftNavBar/LeftNavBar.js";
import Main from "../../Main/Main.js";

import Button from "../../Button/Button";
import PageMessage from "../../PageMessage/PageMessage";
import PageHeader from "../../PageHeader/PageHeader";
import InvestmentsPropertyItem from "../InvestmentsPropertyItem/InvestmentsPropertyItem";
import PageHeaderNavigation from "../PageHeaderNavigation/PageHeaderNavigation";

import InvestmentsNotConnected from "../InvestmentsNotConnected/InvestmentsNotConnected";

import { useMarketplace } from "../../../hooks/useMarketplace";

import styles from "./InvestmentsInProgress.module.css";

const InvestmentsPropertyList = ({ filteredProperties, account }) => {
  const getOwnedValue = (owned) => (account ? owned : "N/A");

  if (!filteredProperties?.length) {
    return (
      <PageMessage
        title={<>You don’t have any investments in progress.</>}
        button={
          <Button large link="/marketplace">
            Visit Marketplace
          </Button>
        }
      />
      // <span className={styles.Loading}>
      //   You don’t have any investments in progress.
      // </span>
    );
  } else {
    return (
      <div className={styles.InvestmentsInProgress}>
        {filteredProperties.map((property) => (
          <InvestmentsPropertyItem
            key={property.id}
            id={property.id}
            name={ethers.utils.parseBytes32String(property.name)}
            threshold={property.pledgingThreshold}
            // value={200000}
            totalSupply={property.tokensAvailable}
            minted={property.vouchersCount}
            // nftPrice={property.tokenPriceFormatted}
            projectedIrr={18.64}
            cashPayout={6.44}
            pledgingDueTime={property.pledgingDueTime.toNumber()}
            owned={getOwnedValue(property.vouchersOwned.toNumber())}
            vouchersRedeemed={property.vouchersRedeemed.toNumber()}
            vouchersRefunded={property.vouchersRefunded.toNumber()}
            vouchersOwned={property.vouchersOwned.toNumber()}
          />
        ))}
      </div>
    );
  }
};

const InvestmentsInProgress = ({
  usdcDecimals,
  web3Handler,
  account,
  marketplace,
  user,
  onClickVerify,
  signOut,
}) => {
  const { properties, loading } = useMarketplace({ usdcDecimals, marketplace });

  const filteredInvestedInProgressProperties = properties.filter((item) => {
    return (
      item.pledgingDueTime.toNumber() > getSecondsTimestamp(Date.now()) &&
      item.vouchersOwned.toNumber() > 0
    );
  });

  return (
    <Layout
      topNavBar={
        <TopNavBar
          account={account}
          user={user}
          web3Handler={web3Handler}
          onClickVerify={onClickVerify}
          signOut={signOut}
        />
      }
      leftNavBar={<LeftNavBar selected="my-investments" />}
      // rightNavBar={<RightNavBar />}
      main={
        <Main>
          <PageHeader title="Investments">
            <PageHeaderNavigation tabSelected="in-progress" />
          </PageHeader>
          {!account ? (
            <InvestmentsNotConnected
              account={account}
              web3Handler={web3Handler}
            />
          ) : loading ? (
            <span className={styles.Loading}>Loading...</span>
          ) : (
            <InvestmentsPropertyList
              filteredProperties={filteredInvestedInProgressProperties}
              account={account}
            />
          )}
        </Main>
      }
    />
  );
};

export default InvestmentsInProgress;
