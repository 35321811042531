import { ethers } from "ethers";
import {
  getSecondsTimestamp,
  getDateFromTimestamp,
} from "../../../lib/DateTime";

import Layout from "../../Layout/Layout.js";
import TopNavBar from "../../TopNavBar/TopNavBar.js";
import LeftNavBar from "../../LeftNavBar/LeftNavBar.js";
// import RightNavBar from "../../RightNavBar/RightNavBar.js";
import Main from "../../Main/Main.js";

import PageHeader from "../../PageHeader/PageHeader";
import MarketplacePropertyItem from "../MarketplacePropertyItem/MarketplacePropertyItem";
import PageHeaderNavigation from "../PageHeaderNavigation/PageHeaderNavigation";

import { useMarketplace } from "../../../hooks/useMarketplace";

import styles from "./CompletedProperties.module.css";

const CompletedProperties = ({
  usdcDecimals,
  web3Handler,
  account,
  marketplace,
  user,
  onClickVerify,
  signOut,
}) => {
  const { properties, loading } = useMarketplace({ usdcDecimals, marketplace });

  const filteredCompletedProperties = properties.filter(
    (item) => item.pledgingDueTime.toNumber() <= getSecondsTimestamp(Date.now())
    // (item) => item.pledgingDueTime.toNumber() <= Date.now()
  );

  const getOwnedValue = (owned) => (account ? owned : "N/A");

  return (
    <Layout
      topNavBar={
        <TopNavBar
          account={account}
          user={user}
          web3Handler={web3Handler}
          onClickVerify={onClickVerify}
          signOut={signOut}
        />
      }
      leftNavBar={<LeftNavBar selected="marketplace" />}
      // rightNavBar={<RightNavBar />}
      main={
        <Main>
          <PageHeader title="Property Offering">
            <PageHeaderNavigation tabSelected="completed" />
          </PageHeader>
          {loading ? (
            <span className={styles.Loading}>Loading...</span>
          ) : (
            <>
              {!filteredCompletedProperties?.length ? (
                <span className={styles.Loading}>No Properties</span>
              ) : (
                <div className={styles.CompletedProperties}>
                  {filteredCompletedProperties.map((property) => (
                    <MarketplacePropertyItem
                      key={property.id}
                      id={property.id}
                      completed
                      name={ethers.utils.parseBytes32String(property.name)}
                      threshold={property.pledgingThreshold}
                      value={200000}
                      totalSupply={property.tokensAvailable}
                      minted={property.vouchersCount}
                      nftPrice={property.tokenPriceFormatted}
                      projectedIrr={18.64}
                      cashPayout={6.44}
                      pledgingDueTime={property.pledgingDueTime.toNumber()}
                      owned={getOwnedValue(property.vouchersOwned.toNumber())}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </Main>
      }
    />
  );
};

export default CompletedProperties;
