import ModalMessage from "../ModalMessage/ModalMessage";
import Button from "../Button/Button";

import { ReactComponent as VerificationIcon } from "../../assets/icons/verification-icon.svg";

const ModalMessageVerification = ({ onClickClose, onClickNo, onClickYes }) => (
  <ModalMessage
    onClickClose={onClickClose}
    icon={<VerificationIcon />}
    title={<>Identity Verification Required</>}
    message={
      <>
        Before inversting in a property, you are required to verify your
        identity. Would you like to start the verification process?
      </>
    }
    button={
      <>
        <Button
          large
          onClick={onClickYes}
          wide={false}
          style={{ marginRight: 20 }}
        >
          Yes
        </Button>
        <Button large onClick={onClickNo} ghost wide={false}>
          No
        </Button>
      </>
    }
  />
);
export default ModalMessageVerification;
