import { ethers } from "ethers";
import { getSecondsTimestamp } from "../../../lib/DateTime";

import Layout from "../../Layout/Layout.js";
import TopNavBar from "../../TopNavBar/TopNavBar.js";
import LeftNavBar from "../../LeftNavBar/LeftNavBar.js";
import Main from "../../Main/Main.js";

import PageHeader from "../../PageHeader/PageHeader";
import InvestmentsPropertyItem from "../InvestmentsPropertyItem/InvestmentsPropertyItem";
import PageHeaderNavigation from "../PageHeaderNavigation/PageHeaderNavigation";

import InvestmentsNotConnected from "../InvestmentsNotConnected/InvestmentsNotConnected";

import { useMarketplace } from "../../../hooks/useMarketplace";

import styles from "./InvestmentsCompleted.module.css";

const InvestmentsPropertyList = ({ filteredProperties, account }) => {
  const getOwnedValue = (owned) => (account ? owned : "N/A");

  if (!filteredProperties?.length) {
    return <span className={styles.Loading}>No Properties</span>;
  } else {
    return (
      <div className={styles.InvestmentsCompleted}>
        {filteredProperties.map((property) => (
          <InvestmentsPropertyItem
            completed
            mintable
            invested
            key={property.id}
            id={property.id}
            name={ethers.utils.parseBytes32String(property.name)}
            threshold={property.pledgingThreshold}
            // value={200000}
            totalSupply={property.tokensAvailable}
            minted={property.vouchersCount}
            // nftPrice={100}
            projectedIrr={18.64}
            cashPayout={6.44}
            owned={getOwnedValue(property.vouchersOwned.toNumber())}
            vouchersRedeemed={property.vouchersRedeemed.toNumber()}
            vouchersRefunded={property.vouchersRefunded.toNumber()}
            vouchersOwned={property.vouchersOwned.toNumber()}
          />
        ))}
      </div>
    );
  }
};

const InvestmentsCompleted = ({
  usdcDecimals,
  web3Handler,
  account,
  marketplace,
  user,
  onClickVerify,
  signOut,
}) => {
  const { properties, loading } = useMarketplace({ usdcDecimals, marketplace });

  const filteredInvestedCompletedProperties = properties.filter(
    (item) =>
      item.pledgingDueTime.toNumber() <= getSecondsTimestamp(Date.now()) &&
      item.vouchersOwned.toNumber() > 0
  );

  return (
    <Layout
      topNavBar={
        <TopNavBar
          account={account}
          user={user}
          web3Handler={web3Handler}
          onClickVerify={onClickVerify}
          signOut={signOut}
        />
      }
      leftNavBar={<LeftNavBar selected="my-investments" />}
      main={
        <Main>
          <PageHeader title="Investments">
            <PageHeaderNavigation tabSelected="completed" />
          </PageHeader>
          {!account ? (
            <InvestmentsNotConnected
              account={account}
              web3Handler={web3Handler}
            />
          ) : loading ? (
            <span className={styles.Loading}>Loading...</span>
          ) : (
            <InvestmentsPropertyList
              filteredProperties={filteredInvestedCompletedProperties}
              account={account}
            />
          )}
        </Main>
      }
    />
  );
};

export default InvestmentsCompleted;
