import React, { useState } from "react";
import classnames from "classnames";

import styles from "./InfoPopover.module.css";

import { ReactComponent as InfoIcon } from "../../assets/icons/info-icon.svg";

const InfoPopover = ({
  delay = 400,
  direction = "top",
  content,
  icon = <InfoIcon />,
}) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className={styles.InfoPopover}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* {children} */}
      {icon}
      {active && (
        <div
          className={classnames(
            styles.InfoPopoverTip,
            styles[`InfoPopoverTip-${direction}`]
          )}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default InfoPopover;
