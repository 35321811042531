import { useState, useEffect, useCallback } from "react";
import { toUsdc } from "../lib/Currency";

const useMintProperty = ({ id, usdcDecimals, marketplace }) => {
  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState({});
  const [justMinted, setJustMinted] = useState(false);

  const loadProperty = useCallback(async () => {
    setLoading(true);

    const item = await marketplace.getPropertyStruct(id);

    const nftPriceToUsdc = toUsdc(item.tokenPrice.toString(), usdcDecimals);
    const allVouchersRedeemed = item.vouchersRedeemed >= item.vouchersOwned;
    const allVouchersRefunded = item.vouchersRefunded >= item.vouchersOwned;

    setProperty({
      ...item,
      nftPriceToUsdc,
      allVouchersRedeemed,
      allVouchersRefunded,
    });
    setLoading(false);
  }, [id, usdcDecimals, marketplace]);

  useEffect(() => {
    loadProperty();
  }, [loadProperty]);

  const mintProperty = useCallback(async () => {
    setLoading(true);

    // try {
    const tx = await marketplace.determineFundingStatus(id);
    await tx.wait();
    // } catch (error) {}

    setJustMinted(true);
    setLoading(false);
  }, [marketplace, id]);

  return { property, loading, mintProperty, justMinted };
};

export { useMintProperty };
