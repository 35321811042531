import { ethers } from "ethers";
import { useParams } from "react-router";

import Layout from "../../Layout/Layout.js";
import TopNavBar from "../../TopNavBar/TopNavBar.js";
import LeftNavBar from "../../LeftNavBar/LeftNavBar.js";
// import RightNavBar from "../../RightNavBar/RightNavBar.js";
import Main from "../../Main/Main.js";

// import { getDateFromTimestamp } from "../../../lib/DateTime";

import MarketplacePurchaseItem from "../MarketplacePurchaseItem/MarketplacePurchaseItem";

import { useMarketplacePurchase } from "../../../hooks/useMarketplacePurchase.js";

import styles from "./MarketplacePurchase.module.css";

const MarketplacePurchase = ({
  web3Handler,
  account,
  usdcDecimals,
  usdc,
  marketplace,
  user,
  clientToken,
  getClientToken,
  onClickVerify,
  signOut,
}) => {
  const { id } = useParams();
  const { property, loading, purchaseProperty } = useMarketplacePurchase({
    id,
    usdcDecimals,
    usdc,
    marketplace,
    user,
  });

  const getOwnedValue = (owned) => (account ? owned : "N/A");

  return (
    <Layout
      topNavBar={
        <TopNavBar
          account={account}
          user={user}
          web3Handler={web3Handler}
          onClickVerify={onClickVerify}
          signOut={signOut}
        />
      }
      leftNavBar={<LeftNavBar selected="marketplace" />}
      // rightNavBar={<RightNavBar />}
      main={
        <Main>
          {loading ? (
            <span className={styles.Loading}>Loading...</span>
          ) : (
            <div className={styles.MarketplacePurchase}>
              <MarketplacePurchaseItem
                id={property.id.toNumber()}
                name={ethers.utils.parseBytes32String(property.name)}
                threshold={property.pledgingThreshold}
                value={200000}
                totalSupply={property.tokensAvailable.toNumber()}
                minted={property.vouchersCount}
                nftPrice={property.tokenPrice}
                nftPriceToUsdc={property.nftPriceToUsdc}
                projectedIrr={18.64}
                cashPayout={6.44}
                pledgingDueTime={property.pledgingDueTime.toNumber()}
                projectedReturn={25.08}
                account={account}
                purchaseProperty={purchaseProperty}
                owned={getOwnedValue(property.vouchersOwned.toNumber())}
                user={user}
                clientToken={clientToken}
                getClientToken={getClientToken}
                web3Handler={web3Handler}
                onClickVerify={onClickVerify}
                signOut={signOut}
              />
            </div>
          )}
        </Main>
      }
    />
  );
};

export default MarketplacePurchase;
