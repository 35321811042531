import { Link } from "react-router-dom";
import classnames from "classnames";

import styles from "./LeftNavBar.module.css";

const LeftNavBar = ({ selected }) => {
  return (
    <div className={styles.LeftNavBar}>
      <Link
        to="/"
        className={classnames(
          styles.Item,
          styles.Marketplace,
          selected === "marketplace" ? styles.Selected : ""
        )}
      >
        Marketplace
      </Link>
      <Link
        to="/my-investments"
        className={classnames(
          styles.Item,
          styles.MyInvestments,
          selected === "my-investments" ? styles.Selected : ""
        )}
      >
        My Investments
      </Link>
      {/* <Link
        to="/transaction-history"
        className={classnames(
          styles.Item,
          styles.TransactionHistory,
          selected === "transaction-history" ? styles.Selected : ""
        )}
      >
        Transaction History
      </Link> */}
      {/* <Link
        to="/"
        className={classnames(
          styles.Item,
          styles.Settings,
          selected === "settings" ? styles.Selected : ""
        )}
      >
        Settings
      </Link> */}
    </div>
  );
};

export default LeftNavBar;
