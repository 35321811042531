import { useState } from "react";

import Button from "../../Button/Button";
import PageMessage from "../../PageMessage/PageMessage";
import ModalMessage from "../../ModalMessage/ModalMessage";
import WalletDropdown from "../../WalletDropdown/WalletDropdown";

import styles from "./InvestmentsNotConnected.module.css";

const InvestmentsNotConnected = ({
  web3Handler,
  account,
  user,
  onClickVerify,
  signOut,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  return (
    <>
      <div className={styles.InvestmentsNotConnected}>
        <PageMessage
          title={<>It looks like you haven't connected your wallet.</>}
          button={
            <Button large onClick={handleOpenModal}>
              connect wallet
            </Button>
          }
        />
      </div>
      {modalOpen && (
        <ModalMessage
          onClickClose={handleCloseModal}
          message={
            <WalletDropdown
              account={account}
              user={user}
              web3Handler={web3Handler}
              onClickVerify={onClickVerify}
              signOut={signOut}
            />
          }
        />
      )}
    </>
  );
};

export default InvestmentsNotConnected;
