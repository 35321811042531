import styles from "./PropertyProgressBar.module.css";

const PropertyProgressBar = ({
  progress = 0,
  mintedFormatted,
  totalSupplyFormatted,
}) => {
  return (
    <>
      <div className={styles.ProgressBarPlaceholder}>
        <div
          className={styles.ProgressBar}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className={styles.ProgressFooter}>
        <span>Total NFTs Lazy Minted:</span>
        <span>
          {mintedFormatted}/{totalSupplyFormatted} ({progress}%)
        </span>
      </div>
    </>
  );
};

export default PropertyProgressBar;
