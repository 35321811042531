import { useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { getMilisecondsTimestamp } from "../../../lib/DateTime";

import WalletDropdown from "../../WalletDropdown/WalletDropdown";
import HelpInfo from "../../HelpInfo/HelpInfo";
import PropertyDetailsBox from "../../PropertyItem/PropertyDetailsBox/PropertyDetailsBox";
import PropertyProgressBar from "../../PropertyItem/PropertyProgressBar/PropertyProgressBar";
import PropertyTimeRemaining from "../../PropertyItem/PropertyTimeRemaining/PropertyTimeRemaining";
import PropertyImage from "../../PropertyItem/PropertyImage/PropertyImage";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import Alink from "../../Alink/Alink";
import ModalMessage from "../../ModalMessage/ModalMessage";
import ModalMessageConnectWallet from "../../Modals/ModalMessageConnectWallet";
import ModalMessageVerification from "../../Modals/ModalMessageVerification";

import styles from "./MarketplacePurchaseItem.module.css";

import property from "../../../assets/img/property1.png";

const MarketplacePurchaseItem = ({
  id,
  name,
  threshold = 0,
  value = 0,
  totalSupply = 0,
  minted = 0,
  nftPrice = 0,
  nftPriceToUsdc = "",
  projectedIrr = 0,
  cashPayout = 0,
  projectedReturn = 0,
  pledgingDueTime = 0,
  account,
  purchaseProperty,
  owned = 0,
  user,
  getClientToken,
  web3Handler,
  onClickVerify,
  signOut,
}) => {
  const navigate = useNavigate();
  const [modalOpen0, setModalOpen0] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [modalOpen5, setModalOpen5] = useState(false);
  const [modalOpen6, setModalOpen6] = useState(false);
  const [shares, setShares] = useState(1);
  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState(false);

  const purchaseAmount = nftPriceToUsdc * shares;
  const purchaseAmountFormatted = purchaseAmount.toLocaleString();

  const progress = Math.round((minted / totalSupply) * 100);

  const totalSupplyFormatted = totalSupply.toLocaleString();
  const valueFormatted = value.toLocaleString();
  const mintedFormatted = minted.toLocaleString();
  const nftPriceFormatted = nftPriceToUsdc.toLocaleString();
  const projectedIrrFormatted = projectedIrr;

  const isThresholdSurpassed = progress >= threshold;

  const handleOpenModal0 = async () => {
    if (!consent) setConsentError(true);
    else if (!user.earlyBird) setModalOpen6(true); // EARLYBIRD
    else if (!account) setModalOpen0(true);
    else if (!user.verified) setModalOpen3(true);
    else {
      const purchase = await purchaseProperty(id, nftPrice.toString(), shares);
      navigate("/marketplace/purchased", {
        state: {
          amount: purchase.amount,
          shares,
          nftPrice: nftPriceToUsdc,
          name,
          value: valueFormatted,
          totalSupply: totalSupplyFormatted,
          etherscanUrl: `https://mumbai.polygonscan.com/tx/${purchase.tx}`,
          id,
          pledgingDueTime: getMilisecondsTimestamp(pledgingDueTime),
          threshold,
          minted,
          totalSupply,
        },
      });
    }
  };
  const handleCloseModal0 = () => setModalOpen0(false);

  const handleOpenModal1 = () => setModalOpen1(true);
  const handleCloseModal1 = () => setModalOpen1(false);

  const handleOpenModal2 = () => setModalOpen2(true);
  const handleCloseModal2 = () => setModalOpen2(false);

  const handleCloseModal3 = () => setModalOpen3(false);

  const handleOpenModal4 = () => setModalOpen4(true);
  const handleCloseModal4 = () => setModalOpen4(false);

  const handleOpenModal5 = () => setModalOpen5(true);
  const handleCloseModal5 = () => setModalOpen5(false);

  const handleCloseModal6 = () => setModalOpen6(false);

  const handleOnConnected = () => handleCloseModal5();

  const handleChangeShares = (event) => {
    const numberShares = event.target.value;

    if (numberShares < 1) setShares(1);
    else if (numberShares > totalSupply) setShares(totalSupply);
    else setShares(numberShares);
  };

  const handleChangeConsent = (event) => {
    setConsentError(false);
    setConsent(event.target.checked);
  };

  const handleVerification = () => {
    getClientToken();
    handleCloseModal3();
  };

  const handleOnConnect = () => {
    handleCloseModal0();
    handleOpenModal5();
    // web3Handler();
  };

  // TO-DO handle that is the property is not investable not allow to

  return (
    <>
      <div className={styles.PropertyItem}>
        <div className={styles.Left}>
          <PropertyImage property={property} />
          <div className={styles.LeftExtra}>
            <span className={styles.PurchaseBoxTitle}>
              Amount of NFTs to purchase:
            </span>
            <div className={styles.PurchaseBoxInput}>
              <Input
                type="number"
                value={shares}
                onChange={handleChangeShares}
              />
              {/* <input
                type="number"
                value={shares}
                onChange={handleChangeShares}
              /> */}
            </div>
            <div className={styles.PurchaseBoxTotal}>
              <span className={styles.PurchaseBoxLabel}>
                Total amount (USDC):
              </span>
              <span className={styles.PurchaseBoxAmount}>
                {purchaseAmountFormatted}
              </span>
            </div>
          </div>
          <PropertyTimeRemaining time={pledgingDueTime} />
        </div>
        <div className={styles.Center}>
          <div className={styles.PropertyDetails}>
            <div className={styles.Top}>
              <span className={styles.PropertyTitle}>{name}</span>
            </div>
            <div className={styles.Info}>
              <HelpInfo onClick={handleOpenModal1}>
                What do I get from this NFT?
              </HelpInfo>
              <HelpInfo onClick={handleOpenModal2}>
                What if the threshold is not met?
              </HelpInfo>
            </div>
            <div className={styles.Progress}>
              <PropertyProgressBar
                progress={progress}
                mintedFormatted={mintedFormatted}
                totalSupplyFormatted={totalSupplyFormatted}
              />
            </div>
            <div className={styles.Details1}>
              <PropertyDetailsBox
                title="Minimum Threshold"
                details={<>{threshold}%</>}
                helper={
                  isThresholdSurpassed ? (
                    <>Threshold surpassed</>
                  ) : (
                    <>
                      Additional investment is needed
                      <br />
                      to meet the threshold
                    </>
                  )
                }
              />
            </div>
            <div className={styles.Details2}>
              <PropertyDetailsBox
                title="Total Property Value"
                details={<>{valueFormatted} USDC</>}
              />
            </div>
            <div className={styles.Details3}>
              <PropertyDetailsBox
                title="Total NFTs"
                details={totalSupplyFormatted}
              />
            </div>
            <div className={styles.Details4}>
              <PropertyDetailsBox
                title="Cost To Own 1 NFT"
                details={<>{nftPriceFormatted} USDC</>}
              />
            </div>
            <div className={styles.Details5}>
              <PropertyDetailsBox
                title="NFTs You've Lazy Minted"
                tooltip={
                  <>
                    This is the number of NFTs that you purchased when you made
                    <br />
                    your investment. Because the investment period has not ended
                    <br />
                    yet, we use a “lazy minting” process so you don’t officially
                    <br />
                    mint the NFT and pay for gas fees until we know the
                    investment
                    <br />
                    period has completed. Once the investment period is
                    complete,
                    <br />
                    you will have the ability to officially mint your NFTs and
                    own
                    <br />a part of the property.
                  </>
                }
                details={owned}
              />
            </div>
            <div className={styles.Details6}>
              <PropertyDetailsBox
                title="Projected IRR"
                tooltip={
                  <>
                    The internal rate of return (IRR) is a yearly percentage
                    equal
                    <br />
                    to the amount of expected appreciation plus positive cash
                    flow
                    <br />
                    for the property for each year. As this is an estimation,
                    the
                    <br />
                    actual number may vary.
                  </>
                }
                details={<>{projectedIrrFormatted}%</>}
              />
            </div>
            <div className={styles.Details7}>
              <PropertyDetailsBox
                title="Cash Payout"
                tooltip={
                  <>
                    The cash payout is the annualized monthly rental payments
                    you
                    <br />
                    can expect to receive as an owner of the property as a<br />
                    percentage of your investment amount. For example, a 5% cash
                    <br />
                    payout with $1,000 invested means you can expect to receive
                    <br />
                    $50 per year from your investment.
                  </>
                }
                details={<>{cashPayout}%</>}
              />
            </div>
            <div className={styles.Details8}>
              <PropertyDetailsBox
                title="Projected Return"
                details={<>{projectedReturn}%</>}
              />
            </div>
          </div>
          <div className={styles.PropertyDetailsFooter}>
            <div className={styles.PropertyDetailsConsent}>
              <div
                className={classNames(
                  styles.PropertyDetailsConsentCheck,
                  consentError && styles.PropertyDetailsConsentCheckError
                )}
              >
                <div className={styles.PropertyDetailsConsentCheckInput}>
                  <input
                    type="checkbox"
                    checked={consent}
                    onChange={handleChangeConsent}
                  />
                </div>
                <span>
                  I understand this is a{" "}
                  <Alink onClick={handleOpenModal4}>lazy mint.</Alink>
                </span>
              </div>
              <div className={styles.PropertyDetailsConsentInfo}>
                By clicking continue, you'll be taken to MetaMask where you can
                complete your transaction.
              </div>
            </div>

            <div className={styles.PropertyDetailsFeesWarning}>
              <span>
                In order to lazy mint, you will need to use a minimal amount of
                MATIC (typically less than $0.01) from your wallet to pay for
                the blockchain fees. Need help getting MATIC into your wallet?{" "}
              </span>
              <Alink
                newWindow
                link="https://decentralizedcreator.com/send-matic-from-coinbase-to-metamask/ "
              >
                View this easy guide
              </Alink>
            </div>
            <div className={styles.PropertyDetailsButtons}>
              <Button large ghost link="/marketplace/available">
                Cancel
              </Button>
              <Button onClick={handleOpenModal0} large>
                Continue
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.Right}></div>
      </div>
      {modalOpen0 && (
        <ModalMessageConnectWallet
          onClickClose={handleCloseModal0}
          onClickConnect={handleOnConnect}
        />
      )}
      {modalOpen3 && (
        <ModalMessageVerification
          onClickClose={handleCloseModal3}
          onClickNo={handleCloseModal3}
          onClickYes={handleVerification}
        />
      )}
      {modalOpen1 && (
        <ModalMessage
          isOpen={modalOpen1}
          onClickClose={handleCloseModal1}
          message={
            <>
              By purchasing an NFT of this property, you are acquiring partial
              ownership of this property. Each NFT corresponds to a membership
              interest in the LLC that will own the property, proportional to
              the total number of NFTs minted. This entitles you to receive a
              portion of the rental income and share in the appreciation of the
              property over time. See the FAQs for additional detail about how
              this all works.
            </>
          }
        />
      )}
      {modalOpen2 && (
        <ModalMessage
          isOpen={modalOpen2}
          onClickClose={handleCloseModal2}
          message={
            <>
              The seller of this property has specified a minimum number of NFTs
              that need to be purchased prior to the end of the investment
              period in order for this property to be successfully sold on
              Closin. If this threshold is not met, you will be refunded your
              entire investment amount.
            </>
          }
        />
      )}
      {modalOpen4 && (
        <ModalMessage
          isOpen={modalOpen4}
          onClickClose={handleCloseModal4}
          message={
            <>
              “Lazy minting” an NFT is equivalent to pledging to acquire an NFT
              for this property once the investment period has ended. Once the
              investment period is over, you will have the ability to officially
              mint and own part of the property. Think of this like pledging to
              back a crowdfunding campaign.
            </>
          }
        />
      )}
      {modalOpen5 && (
        <ModalMessage
          onClickClose={handleCloseModal5}
          message={
            <WalletDropdown
              account={account}
              user={user}
              web3Handler={web3Handler}
              onClickVerify={onClickVerify}
              signOut={signOut}
              onConnected={handleOnConnected}
            />
          }
        />
      )}
      {modalOpen6 && (
        <ModalMessage
          isOpen={modalOpen6}
          onClickClose={handleCloseModal6}
          message={
            <>
              Investing in this property is currently reserved
              <br /> for presale members who have whitelisted.
            </>
          }
        />
      )}
    </>
  );
};

export default MarketplacePurchaseItem;
