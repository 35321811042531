import PageHeader from "../../PageHeader/PageHeader";
import PageHeaderNavigation from "../PageHeaderNavigation/PageHeaderNavigation";
import Button from "../../Button/Button";
import PageMessage from "../../PageMessage/PageMessage";

import styles from "./InvestmentsVerifyIdentityMessage.module.css";

const InvestmentsVerifyIdentityMessage = () => {
  return (
    <>
      <PageHeader title="Investment">
        <PageHeaderNavigation tabSelected="in-progress" />
      </PageHeader>
      <div className={styles.InvestmentsVerifyIdentityMessage}>
        <PageMessage
          title={
            <>
              To comply with US security laws, you must
              <br />
              verify your identity before investing
            </>
          }
          button={
            <Button large link="/my-investments/verified">
              Verify Identity
            </Button>
          }
        />
      </div>
    </>
  );
};

export default InvestmentsVerifyIdentityMessage;
