import Layout from "../../Layout/Layout.js";
import TopNavBar from "../../TopNavBar/TopNavBar.js";
import LeftNavBar from "../../LeftNavBar/LeftNavBar.js";
// import RightNavBar from "../../RightNavBar/RightNavBar.js";
import Main from "../../Main/Main.js";

import PageHeader from "../../PageHeader/PageHeader";
import PageHeaderNavigation from "../PageHeaderNavigation/PageHeaderNavigation";
import Button from "../../Button/Button";
import PageMessage from "../../PageMessage/PageMessage";

import styles from "./InvestmentsNoCompleted.module.css";

const InvestmentsNoCompleted = ({
  usdcDecimals,
  web3Handler,
  account,
  marketplace,
  user,
  onClickVerify,
  signOut,
}) => {
  return (
    <Layout
      topNavBar={
        <TopNavBar
          account={account}
          user={user}
          web3Handler={web3Handler}
          onClickVerify={onClickVerify}
          signOut={signOut}
        />
      }
      leftNavBar={<LeftNavBar selected="my-investments" />}
      // rightNavBar={<RightNavBar />}
      main={
        <Main>
          <PageHeader title="Investments">
            <PageHeaderNavigation tabSelected="completed" />
          </PageHeader>
          <div className={styles.InvestmentsNoCompleted}>
            <PageMessage
              title={
                <>It looks like you don't have any completed investments yet</>
              }
              button={
                <Button large link="/marketplace">
                  View Properties
                </Button>
              }
            />
          </div>
        </Main>
      }
    />
  );
};

export default InvestmentsNoCompleted;
