import React, { useState } from "react";
import classnames from "classnames";

import styles from "./InfoPopoverLarge.module.css";

const InfoPopoverLarge = ({
  delay = 400,
  direction = "bottom",
  content,
  children,
  width,
}) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  const widthStyle = width ? { width } : {};

  return (
    <div
      className={styles.InfoPopover}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && (
        <div
          className={classnames(
            styles.InfoPopoverTip,
            styles[`InfoPopoverTip-${direction}`]
          )}
          style={{ ...widthStyle }}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default InfoPopoverLarge;
