import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navigation from "./Navbar";
import Investments from "./Investments/Investments.js";
import InvestmentsVerifyIdentityMessage from "./Investments/InvestmentsVerifyIdentityMessage/InvestmentsVerifyIdentityMessage.js";
import InvestmentsInProgress from "./Investments/InvestmentsInProgress/InvestmentsInProgress";
import InvestmentsCompleted from "./Investments/InvestmentsCompleted/InvestmentsCompleted";
import InvestmentsNoCompleted from "./Investments/InvestmentsNoCompleted/InvestmentsNoCompleted";
import InvestmentsNotConnected from "./Investments/InvestmentsNotConnected/InvestmentsNotConnected";
import InvestmentsMintMessage from "./Investments/InvestmentsMintMessage/InvestmentsMintMessage";
import InvestmentsMintedMessage from "./Investments/InvestmentsMintedMessage/InvestmentsMintedMessage";
import InvestmentsRefundMessage from "./Investments/InvestmentsRefundMessage/InvestmentsRefundMessage";
import InvestmentsRefundedMessage from "./Investments/InvestmentsRefundedMessage/InvestmentsRefundedMessage";
import MarketplaceLazyMinted from "./Marketplace/MarketplaceLazyMinted/MarketplaceLazyMinted";
import MarketplaceVerifiedIdentityMessage from "./Marketplace/MarketplaceVerifiedIdentityMessage/MarketplaceVerifiedIdentityMessage.js";
import Marketplace from "./Marketplace/Marketplace.js";
import AvailableProperties from "./Marketplace/AvailableProperties/AvailableProperties";
import CompletedProperties from "./Marketplace/CompletedProperties/CompletedProperties";
import MarketplacePurchase from "./Marketplace/MarketplacePurchase/MarketplacePurchase";
import Transactions from "./Transactions/Transactions";
import Create from "./Create.js";
import MyListedItems from "./MyListedItems.js";
import ModalLoader from "./ModalLoader.js";
import MyPurchases from "./MyPurchases.js";
import MarketplaceAbi from "../contractsData/Marketplace.json";
import MarketplaceAddress from "../contractsData/Marketplace-address.json";
import ControlAbi from "../contractsData/Control.json";
import ControlAddress from "../contractsData/Control-address.json";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { Spinner } from "react-bootstrap";
import BerbixVerificationClosin from "./BerbixVerificationClosin/BerbixVerificationClosin";

import "./App.css";

import { auth, functions, httpsCallable } from "../firebase.js";
import {
  signInWithCustomToken,
  setPersistence,
  inMemoryPersistence,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

const UsdcAddressMainnet = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";
const UsdcDecimalsMainnet = 6;
const UsdcAddressGoerli = "0x07865c6e87b9f70255377e024ace6630c1eaa37f"; // 0x544d6a84bfc818220c2eeadd7ad3ca831d032808
const UsdcDecimalsGoerli = 6;
const UsdcAddressMumbai = "0xe11A86849d99F524cAC3E7A0Ec1241828e332C62";
const UsdcDecimalsMumbai = 18;

const UsdcAddress = UsdcAddressMainnet;
const UsdcDecimals = UsdcDecimalsMainnet;

const UsdcAbi = [
  "function approve(address spender, uint256 value) public returns (bool success)",
  // "function transferFrom(address from, address to, uint256 value) public returns (bool success)",
  "function transfer(address to, uint256 value) public returns (bool success)",
  "function balanceOf(address account) external view returns (uint256)",
];

function App() {
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [user, setUser] = useState({});
  const [clientToken, setClientToken] = useState(null);
  const [nonce, setNonce] = useState(null);
  const [signer, setSigner] = useState(null);
  const [control, setControl] = useState({});
  const [marketplace, setMarketplace] = useState();
  const [usdc, setUsdc] = useState({});
  const [loader, setLoader] = useState(false);
  const [showBerbix, setShowBerbix] = useState(false);

  useEffect(() => {
    const start = async () => {
      // await init();
      // await web3Handler();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const {
            data: { user: userDetails },
          } = await httpsCallable(functions, "getUserDetails")();
          setUser(userDetails);
          // setAccount(user.address);
        } else {
        }
      });
    };

    start();

    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const marketplaceJsonRpc = new ethers.Contract(
        MarketplaceAddress.address,
        MarketplaceAbi.abi,
        provider
      );
      setMarketplace(marketplaceJsonRpc);
    }

    // setInterval(() => {
    //   httpsCallable(functions, "checkUsdcTransferRequest")();
    // }, 10000);
  }, []);

  const logIn = async (address) => {
    const signIn = httpsCallable(functions, "signIn");
    const result = await signIn({ address });
    const idToken = result.data.token;

    await setPersistence(auth, inMemoryPersistence);
    const userCredential = await signInWithCustomToken(auth, idToken);
    // return userCredential.user;
  };

  const init = async () => {
    window.ethereum.on("chainChanged", (chainId) => {
      window.location.reload();
    });

    window.ethereum.on("accountsChanged", async function (accounts) {
      setAccount(accounts[0]);
      await web3Handler();
    });

    await loadContracts();
  };

  const web3Handler = async () => {
    await init();

    let loadedAccounts;
    if (!accounts) {
      loadedAccounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      setAccounts(accounts);
    } else {
      loadedAccounts = accounts;
    }

    await logIn(loadedAccounts[0]);

    setAccount(loadedAccounts[0]);

    // Events
    window.fbq("trackCustom", "WalletConnected");
    // Wallet Connected (webapp)
    window.gtag("event", "conversion", {
      send_to: "AW-10796739490/lgfsCJyyvJgYEKLXpJwo",
    });
  };

  const getClientTokenHandler = async () => {
    // I have also added to return the nonce for verification purposes in this endpoint,
    // so this enpoint name should change to something like, getVerificationTokens
    const getClientToken = httpsCallable(functions, "getClientToken");
    const result = await getClientToken();
    const clientToken = result.data.clientToken;
    const nonce = result.data.nonce;

    setClientToken(clientToken);
    setShowBerbix(true);
    setNonce(nonce);
  };

  const loadContracts = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    setSigner(signer);

    const marketplace = new ethers.Contract(
      MarketplaceAddress.address,
      MarketplaceAbi.abi,
      signer
    );
    setMarketplace(marketplace);

    const control = new ethers.Contract(
      ControlAddress.address,
      ControlAbi.abi,
      signer
    );
    setControl(control);

    const usdc = new ethers.Contract(UsdcAddress, UsdcAbi, signer);
    setUsdc(usdc);

    setLoading(false);
  };

  const signMessage = async (address, nonce) => {
    if (!address || !nonce)
      return { success: false, error: "Address or nonce are undefined." };

    const someHash =
      "0x0123456789012345678901234567890123456789012345678901234567890123";
    const message = `Signing Message with Nonce: ${nonce}`;

    // Sign
    const payload = ethers.utils.defaultAbiCoder.encode(
      ["bytes32", "string", "address"],
      [someHash, message, address]
    );
    const payloadHash = ethers.utils.keccak256(payload);
    const signature = await signer.signMessage(
      ethers.utils.arrayify(payloadHash)
    );

    return { success: true, signature };
  };

  const showHideLoader = (loading) => {
    setLoader(loading);
  };

  const handleOnComplete = async () => {
    setLoader(true);
    setShowBerbix(false);
    const signature = await signMessage(account, nonce);
    const result = await httpsCallable(
      functions,
      "setUserVerification"
    )({ signature: signature.signature });
    const isVerified = result.data.status;
    const { kycAction, kycFlags } = result.data;
    setUser({
      ...user,
      verified: isVerified,
      kycAction,
      kycFlags,
    });
    setLoader(false);

    // Events
    if (isVerified) {
      window.fbq("trackCustom", "WalletConnectedAccept");

      // Whitelisted - Accept (webapp)
      window.gtag("event", "conversion", {
        send_to: "AW-10796739490/1SnbCNHMvJgYEKLXpJwo",
      });
    } else {
      window.fbq("trackCustom", "WalletConnectedReviewReject");

      // Whitelisted - Review, Reject (webapp)
      window.gtag("event", "conversion", {
        send_to: "AW-10796739490/l8D5CPLNtpgYEKLXpJwo",
      });
    }
  };

  const handleVerify = async () => {
    await getClientTokenHandler();
    // if (clientToken && !user.verify) setShowBerbix(true);
  };

  const handleSignOut = async () => {
    await signOut(auth);
    setAccount(null);
    setAccounts(null);
    setUser({});
  };

  // if (!marketplace) {
  //   return null;
  // }

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          {/* <Route
            path="/my-investments"
            element={
              <Investments
                marketplace={marketplace}
                usdc={usdc}
                user={user}
                getClientTokenHandler={getClientTokenHandler}
                showHideLoader={showHideLoader}
                usdcDecimals={UsdcDecimals}
              />
            }
          > */}
          <Route
            exact
            path="/my-investments"
            element={<Navigate replace to="/my-investments/in-progress" />}
          />
          <Route
            exact
            path="/my-investments/in-progress"
            element={
              <InvestmentsInProgress
                account={account}
                web3Handler={web3Handler}
                usdcDecimals={UsdcDecimals}
                marketplace={marketplace}
                user={user}
                onClickVerify={handleVerify}
                signOut={handleSignOut}
              />
            }
          />
          <Route
            exact
            path="/my-investments/completed"
            element={
              <InvestmentsCompleted
                account={account}
                web3Handler={web3Handler}
                usdcDecimals={UsdcDecimals}
                marketplace={marketplace}
                user={user}
                onClickVerify={handleVerify}
                signOut={handleSignOut}
              />
            }
          />
          <Route
            exact
            path="/my-investments/unverified"
            element={<InvestmentsVerifyIdentityMessage />}
          />
          <Route
            exact
            path="/my-investments/no-completed"
            element={
              <InvestmentsNoCompleted
                account={account}
                web3Handler={web3Handler}
                usdcDecimals={UsdcDecimals}
                marketplace={marketplace}
                user={user}
                onClickVerify={handleVerify}
                signOut={handleSignOut}
              />
            }
          />
          <Route
            exact
            path="/my-investments/mint-message/:id"
            element={
              <InvestmentsMintMessage
                account={account}
                web3Handler={web3Handler}
                user={user}
                onClickVerify={handleVerify}
                signOut={handleSignOut}
                usdcDecimals={UsdcDecimals}
                marketplace={marketplace}
              />
            }
          />
          <Route
            exact
            path="/my-investments/minted-message/:id"
            element={
              <InvestmentsMintedMessage
                account={account}
                web3Handler={web3Handler}
                user={user}
                onClickVerify={handleVerify}
                signOut={handleSignOut}
              />
            }
          />
          <Route
            exact
            path="/my-investments/refund-message/:id"
            element={
              <InvestmentsRefundMessage
                account={account}
                web3Handler={web3Handler}
                user={user}
                onClickVerify={handleVerify}
                signOut={handleSignOut}
                usdcDecimals={UsdcDecimals}
                marketplace={marketplace}
              />
            }
          />
          <Route
            exact
            path="/my-investments/refunded-message/:id"
            element={
              <InvestmentsRefundedMessage
                account={account}
                web3Handler={web3Handler}
                user={user}
                onClickVerify={handleVerify}
                signOut={handleSignOut}
              />
            }
          />

          {/* <Route path="*" element={<NoMatch />} /> */}
          {/* </Route> */}
          {/* <Route
            path="/"
            element={
              <Marketplace
                web3Handler={web3Handler}
                account={account}
                marketplace={marketplace}
                usdc={usdc}
                user={user}
                getClientTokenHandler={getClientTokenHandler}
                showHideLoader={showHideLoader}
                usdcDecimals={UsdcDecimals}
              />
            }
          /> */}
          <Route
            exact
            path="/"
            element={<Navigate replace to="/marketplace/available" />}
          />
          <Route
            exact
            path="/marketplace"
            element={<Navigate replace to="/marketplace/available" />}
          />
          <Route
            exact
            path="/marketplace/available"
            element={
              <AvailableProperties
                account={account}
                web3Handler={web3Handler}
                usdcDecimals={UsdcDecimals}
                marketplace={marketplace}
                user={user}
                onClickVerify={handleVerify}
                signOut={handleSignOut}
              />
            }
          />
          <Route
            exact
            path="/marketplace/completed"
            element={
              <CompletedProperties
                account={account}
                web3Handler={web3Handler}
                usdcDecimals={UsdcDecimals}
                marketplace={marketplace}
                user={user}
                onClickVerify={handleVerify}
                signOut={handleSignOut}
              />
            }
          />
          {["/marketplace/purchase/:id", "/my-investments/purchase/:id"].map(
            (path) => (
              <Route
                key={path}
                exact
                path={path}
                element={
                  <MarketplacePurchase
                    account={account}
                    web3Handler={web3Handler}
                    usdcDecimals={UsdcDecimals}
                    marketplace={marketplace}
                    usdc={usdc}
                    user={user}
                    clientToken={clientToken}
                    getClientToken={getClientTokenHandler}
                    // onComplete={handleOnComplete}
                    onClickVerify={handleVerify}
                    signOut={handleSignOut}
                  />
                }
              />
            )
          )}

          <Route
            exact
            path="/marketplace/verified"
            element={
              <MarketplaceVerifiedIdentityMessage
                account={account}
                web3Handler={web3Handler}
                usdcDecimals={UsdcDecimals}
                marketplace={marketplace}
                user={user}
                onClickVerify={handleVerify}
                signOut={handleSignOut}
              />
            }
          />
          {/* <Route
            path="/transaction-history"
            element={
              <Transactions
                marketplace={marketplace}
                usdc={usdc}
                user={user}
                getClientTokenHandler={getClientTokenHandler}
                showHideLoader={showHideLoader}
                usdcDecimals={UsdcDecimals}
              />
            }
          /> */}
          <Route
            exact
            path="/marketplace/purchased"
            element={
              <MarketplaceLazyMinted
                account={account}
                web3Handler={web3Handler}
                user={user}
                onClickVerify={handleVerify}
              />
            }
          />
          {/* <Route
              path="/create"
              element={
                <Create
                  marketplace={marketplace}
                  showHideLoader={showHideLoader}
                  usdcDecimals={UsdcDecimals}
                />
              }
            />
            <Route
              path="/my-listed-items"
              element={
                <MyListedItems marketplace={marketplace} account={account} />
              }
            />
            <Route
              path="/my-purchases"
              element={
                <MyPurchases marketplace={marketplace} account={account} />
              }
            /> */}
        </Routes>
        {showBerbix && (
          <BerbixVerificationClosin
            clientToken={clientToken}
            onComplete={handleOnComplete}
          />
        )}
      </div>
    </BrowserRouter>
  );

  // return (
  //   <BrowserRouter>
  //     <div className="App">
  //       <>
  //         <Navigation
  //           web3Handler={web3Handler}
  //           account={account}
  //           getClientTokenHandler={getClientTokenHandler}
  //           user={user}
  //         />
  //         {clientToken && !user.verify && (
  //           <BerbixVerify
  //             clientToken={clientToken}
  //             showInModal
  //             onComplete={async () => {
  //               setLoader(true);
  //               const signature = await signMessage(account, nonce);
  //               const result = await httpsCallable(
  //                 functions,
  //                 "setUserVerification"
  //               )({ signature: signature.signature });
  //               const isVerified = result.data.status;

  //               setUser({
  //                 ...user,
  //                 verified: isVerified,
  //               });
  //               setLoader(false);
  //             }}
  //           />
  //         )}
  //         <ModalLoader visible={loader} />
  //       </>
  //       <div>
  //         {loading ? (
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               minHeight: "80vh",
  //             }}
  //           >
  //             <Spinner animation="border" style={{ display: "flex" }} />
  //             <p className="mx-3 my-0">Awaiting Metamask Connection...</p>
  //           </div>
  //         ) : (
  //           <Routes>
  //             <Route
  //               path="/"
  //               element={
  //                 <Home
  //                   marketplace={marketplace}
  //                   usdc={usdc}
  //                   user={user}
  //                   getClientTokenHandler={getClientTokenHandler}
  //                   showHideLoader={showHideLoader}
  //                   usdcDecimals={UsdcDecimals}
  //                 />
  //               }
  //             />
  //             <Route
  //               path="/create"
  //               element={
  //                 <Create
  //                   marketplace={marketplace}
  //                   showHideLoader={showHideLoader}
  //                   usdcDecimals={UsdcDecimals}
  //                 />
  //               }
  //             />
  //             <Route
  //               path="/my-listed-items"
  //               element={
  //                 <MyListedItems marketplace={marketplace} account={account} />
  //               }
  //             />
  //             <Route
  //               path="/my-purchases"
  //               element={
  //                 <MyPurchases marketplace={marketplace} account={account} />
  //               }
  //             />
  //           </Routes>
  //         )}
  //       </div>
  //     </div>
  //   </BrowserRouter>
  // );
}

export default App;
