import moment from "moment";

import { diffDates, getDateFromTimestamp } from "../../../lib/DateTime";

import styles from "./PropertyTimeRemaining.module.css";

const addLeadingZero = (unit) => {
  return unit < 10 ? "0" + unit : unit;
};

const PropertyTimeRemaining = ({ time }) => {
  const timeObject = getDateFromTimestamp(time * 1000);

  const diff = getDateFromTimestamp(diffDates(Date.now(), timeObject));

  const minutes = addLeadingZero(moment.duration(diff).minutes());
  const hours = addLeadingZero(moment.duration(diff).hours());
  const days = addLeadingZero(moment.duration(diff).days());

  return (
    <div className={styles.TimeRemaining}>
      <span className={styles.TimeRemainingLabel}>
        Investment period ends in:
      </span>
      <div className={styles.Time}>
        <div>
          <span>{days}</span>
          <span>Days</span>
        </div>
        <div>
          <span>{hours}</span>
          <span>Hours</span>
        </div>
        <div>
          <span>{minutes}</span>
          <span>Minutes</span>
        </div>
      </div>
    </div>
  );
};

export default PropertyTimeRemaining;
